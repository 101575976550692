// import React, { useEffect, useState } from 'react';

// function Rentals() {
//   const [attractions, setAttractions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchAttractions = async () => {
//       try {
//         const madridId = 'eyJ1ZmkiOi0yMDkyMTc0fQ%3D%3D'; // Replace with actual ID for Madrid

//         const response = await fetch(`https://booking-com15.p.rapidapi.com/api/v1/attraction/searchAttractions?id=${madridId}&sortBy=trending&page=1&currency_code=INR&languagecode=en-us`, {
//           method: 'GET',
//           headers: {
//             'x-rapidapi-host': 'booking-com15.p.rapidapi.com',
//             'x-rapidapi-key': 'ecf20072f7msh041abcc483f19a9p1875b3jsn41c8da5979ca', // Use your actual RapidAPI key
//             'Content-Type': 'application/json' 
//           }
//         });

//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }

//         const data = await response.json();
//         console.log(data); // Check the structure of data

//         if (data && data.data && Array.isArray(data.data.products)) {
//           setAttractions(data.data.products); // Set attractions correctly
//         } else {
//           setError('No attractions found.');
//         }
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAttractions();
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <div>
//       <h1>Attractions in Madrid</h1>
//       <ul>
//         {attractions.length > 0 ? (
//           attractions.map(attraction => (
//             <li key={attraction.id}>
//               <h2>{attraction.name}</h2>
//               <p>{attraction.description}</p>
//               {/* Display the attraction image */}
//               {attraction.images && attraction.images.length > 0 ? (
//                 <img 
//                   src={attraction.images[0].url} // Correctly accessing the image URL
//                   alt={attraction.name} 
//                   style={{ width: '200px', height: 'auto' }} // Adjust style as necessary
//                 />
//               ) : (
//                 <img 
//                   src='path/to/placeholder-image.jpg' // Fallback placeholder image
//                   alt='Placeholder' 
//                   style={{ width: '200px', height: 'auto' }}
//                 />
//               )}
//             </li>
//           ))
//         ) : (
//           <p>No attractions available.</p>
//         )}
//       </ul>
//     </div>
//   );
// }

// export default Rentals;

// src/RentalsPage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function Rentals() {
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <h2 className="display-5 text-center py-md-2">{translations.rentalsTitle}</h2>
        <p className="text-secondary mb-2 text-center lead fs-4">{translations.rentalsSubtitle}</p>
        
        <div className='container text-center'>
          <a 
            className="text-secondary mb-2 text-center lead fs-4" 
            href='https://www.booking.com/hotel/es/pradalongo.es.html?aid=356980&label=gog235jc-1DCAMoRjiHA0gDWANoF4gBApgBCrgBB8gBDdgBA-gBAYgCAagCA7gC1NOEuAbAAgHSAiRkZmEzMmVkMC00ODQ5LTRiOWYtOWVlZS04MWU1OWE0OTgwMjPYAgTgAgE&sid=e00806726a0bd42b784858526afa9b13&all_sr_blocks=877284402_386999480_2_0_0;checkin=2024-11-18;checkout=2024-11-19;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=877284402_386999480_2_0_0;hpos=1;matching_block_id=877284402_386999480_2_0_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=distance_from_search;sr_pri_blocks=877284402_386999480_2_0_0__13500;srepoch=1728129561;srpvid=ccce544935ee051d;type=total;ucfs=1&' 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {translations.viewRentals}
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Rentals;
