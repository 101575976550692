import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';
import './App.css';

function FAQ() {
  const [language, setLanguage] = useState('en'); // Default language
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'en'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, [language]);

  return (
    <div>
      <Navbar />
      <div className="App-Header">
        <section className="bsb-faq-3 py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center">{translations.faqTitle}</h2>
                <p className="text-secondary text-center lead fs-4">{translations.faqIntro}</p>
                <p className="mb-5 text-center">{translations.faqDescription}</p>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>

          {/* Section: Your Account */}
          <div className="mb-8">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-11 col-xl-10">
                  <div className="d-flex align-items-end mb-5">
                    <i className="bi bi-person-gear me-3 lh-1 display-5"></i>
                    <h3 className="m-0">{translations.yourAccount}</h3>
                  </div>
                </div>
                <div className="col-11 col-xl-10">
                  <div className="accordion accordion-flush" id="faqAccount">
                    {/* Account Question 1 */}
                    <div className="accordion-item bg-transparent border-top border-bottom py-3">
                      <h2 className="accordion-header" id="faqAccountHeading1">
                        <button
                          className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse1"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse1"
                        >
                          {translations.accountQuestion1}
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse1"
                        className="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading1"
                      >
                        <div className="accordion-body">
                          <p>{translations.accountAnswer1}</p>
                        </div>
                      </div>
                    </div>
                    {/* Account Question 2 */}
                    <div className="accordion-item bg-transparent border-bottom py-3">
                      <h2 className="accordion-header" id="faqAccountHeading2">
                        <button
                          className="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse2"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse2"
                        >
                          {translations.accountQuestion2}
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading2"
                      >
                        <div className="accordion-body">
                          <p>{translations.accountAnswer2}</p>
                        </div>
                      </div>
                    </div>
                    {/* Continue adding other account questions here */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Repeat the structure for other FAQ sections like "Placing an Order", etc. */}
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
