// src/HomePage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function Suppliers() {
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="">
        <h2 className="display-5 text-center py-md-2">{translations.suppliersTitle}</h2>
        <p className="text-secondary mb-2 text-center lead fs-4">
          {translations.welcomeMessageSupply}
        </p>
        
        <div className="container mt-4">
          <p className="text-secondary lead fs-5">
            {translations.sourcingCommitment}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.supplierSelection}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.ethicalStandards}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.testingAndAudits}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.productQuality}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.trustAndReliability}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.transparencyCommitment}
          </p>
          <p className="text-secondary lead fs-5">
            {translations.thankYouMessage}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Suppliers;
