import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import { database } from './Firebase';
import { ref, onValue } from 'firebase/database';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function ProductsPage() {
  const { cart, addToCart, isInCart } = useCart();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  useEffect(() => {
    const productsRef = ref(database, 'products');

    const fetchData = () => {
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setProducts(Object.keys(data).map(key => ({
            id: key,
            ...data[key],
          })));
        }
        setLoading(false);
      }, { onlyOnce: true });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className='container'>
          <section className="py-3 py-md-5">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                  <h2 className="mb-4 display-5 text-center">{translations.productsTitle}</h2>
                  <p className="text-secondary mb-5 text-center lead fs-4">
                    {translations.productsDescription}
                  </p>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                </div>
              </div>
            </div>

            <div className="container overflow-hidden">
              <div className="row gy-4 gy-xxl-5">
                {loading ? (
                  <p className="text-center">{translations.loadingText}</p>
                ) : products.length === 0 ? (
                  <p className="text-center">{translations.noProductsText}</p>
                ) : (
                  products.map((product) => (
                    <div className="col-12 col-md-6 col-lg-4 d-flex" key={product.id}>
                      <article className="d-flex">
                        <div className="card1 border border-secondary" style={{ '--bs-card-border-radius': '0', '--bs-card-inner-border-radius': '0' }}>
                          <figure className="card-img-top m-0 overflow-hidden bsb-overlay-hover">
                            <a href="#!" style={{ textDecoration: 'none' }}>
                              <img
                                className="img-fluid bsb-scale bsb-hover-scale-up"
                                loading="lazy"
                                src={product.imgSrc}
                                alt={product.altText}
                              />
                            </a>
                            <figcaption>
                              <h4 className="h6 text-white bsb-hover-fadeInRight mt-2">{translations.readMore}</h4>
                            </figcaption>
                          </figure>
                          <div className="card-body border-0 bg-white p-4">
                            <h4 className="card-title mb-2">
                              <a className="text-dark" href="#!" style={{ textDecoration: 'none' }}>{product.title}</a>
                            </h4>
                            <p className="card-text mb-4">{product.description}</p>
                            <p className="card-price mb-4" style={{ fontWeight: 'bold', color: '#333' }}>{product.price}</p>

                            <button
    onClick={() => addToCart(product)}
    disabled={isInCart(product.id)}
    className={`btn ${isInCart(product.id) ? 'btn-success' : 'btn-outline-dark'} btn-sm`} 
>
    {isInCart(product.id) ? translations.inCartText : translations.addToCartText}
</button>

                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section> 
        </div>
  
        <Footer />
      </div>
    </div>
  );
}

export default ProductsPage;
