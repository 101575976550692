import React from 'react';
import { useCart } from './CartContext';
import { useNavigate, Link } from 'react-router-dom'; // Add Link import for navigation
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { FaTrashAlt } from 'react-icons/fa';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function Cart() {
  const { cart, removeFromCart, updateQuantity } = useCart();
  const navigate = useNavigate();

  const [language, setLanguage] = React.useState('en');
  const [translations, setTranslations] = React.useState(enTranslations);

  React.useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es';
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  const parsePrice = (price) => {
    if (typeof price === 'number') return price; // If price is already a number, return it directly
    return parseFloat(price.toString().replace('€', '').replace(',', '.'));
  };
  

  const totalPrice = cart.reduce((acc, item) => {
    const itemPrice = parsePrice(item.price);
    const itemQuantity = item.quantity || 1;
    return acc + (itemPrice * itemQuantity);
  }, 0).toFixed(2);

  const handleQuantityChange = (id, quantity) => {
    const newQuantity = Math.max(1, parseInt(quantity, 10));
    updateQuantity(id, newQuantity);
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className="container py-5">
          <section className="py-5">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-8">
                <h5 className="text-center mb-4">{translations.yourCart}</h5>
                <div className="card shadow-sm">
                  <div className="card-body">
                    {cart.length === 0 ? (
                      <p className="text-center">
                        {translations.emptyCart}<br />
                        {translations.addItemsToCart}{' '}
                        <Link to="/productspage" className="text-primary">
                          {translations.browseProducts}
                        </Link>
                      </p>
                    ) : (
                      <>
                        <div className="row gy-4">
                          {cart.map(item => (
                            <div className="col-12" key={item.id}>
                              <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
                                <div className="d-flex align-items-center">
                                  <img 
                                    src={item.imgSrc} 
                                    alt={item.altText} 
                                    className="img-thumbnail me-3" 
                                    style={{ width: '80px', height: '80px', objectFit: 'cover' }} 
                                  />
                                  <h6 className="m-0">{item.title}</h6>
                                </div>
                                <h6 className="mb-0">{item.price}</h6>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mt-2">
                                <select 
                                  value={item.quantity || 1}
                                  onChange={(e) => handleQuantityChange(item.id, e.target.value)} 
                                  className="form-select form-select-sm" 
                                  style={{ width: '70px' }} 
                                >
                                  {[...Array(10).keys()].map(num => (
                                    <option key={num + 1} value={num + 1}>{num + 1}</option>
                                  ))}
                                </select>
                                <button className="btn btn-light btn-sm text-danger" onClick={() => removeFromCart(item.id)}>
                                  <FaTrashAlt />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                          <h6>{translations.total}</h6>
                          <h6>{totalPrice}€</h6>
                        </div>
                        <button className='btn btn-primary btn-sm w-100 mt-3' onClick={handleCheckout}>
                          {translations.checkout}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cart;
