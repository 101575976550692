import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import Navbar from './Navbar';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

const SERVICE_ID = 'service_re647qi';
const TEMPLATE_ID = 'template_jueu7e4';
const PUBLIC_KEY = 'FFOFTA_Dq47I-Yt0_';

function Checkout() {
  const { cart, clearCart } = useCart(); // Cart functions
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    city: '',
    postCode: '',
    addressStreet: '',
    phoneNumber: '',
    floorApartment: '', // Optional field
    email: '',
    additionalDetails: '', // New field for extra details
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null); // State for order details

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; 
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateTotalPrice = () => {
    return cart.reduce((acc, item) => {
      const itemPrice = parseFloat(String(item.price).replace('€', '').replace(',', '.'));
      const itemQuantity = item.quantity || 1;
      return acc + (itemPrice * itemQuantity);
    }, 0).toFixed(2);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const totalPrice = calculateTotalPrice();
  
    const templateParams = {
      first_name: formData.firstName,
      surname: formData.surname,
      city: formData.city,
      post_code: formData.postCode,
      address_street: formData.addressStreet,
      phone_number: formData.phoneNumber,
      floor_apartment: formData.floorApartment || 'N/A',
      email: formData.email,
      additional_details: formData.additionalDetails || 'N/A', // Send extra details in the email
      cart_items: cart.map(item => `${item.title} (Qty: ${item.quantity || 1}, Price: ${item.price})`).join(', '),
      total_price: `${totalPrice}€`,
    };
  
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then(() => {
        setOrderDetails({
          items: cart,
          totalPrice: totalPrice,
        });
        
        toast.success(translations.Checkout.emailSuccessMessage || "Email sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        
        clearCart(); 
        setFormData({   
          firstName: '',
          surname: '',
          city: '',
          postCode: '',
          addressStreet: '',
          phoneNumber: '',
          floorApartment: '',
          email: '',
          additionalDetails: '', // Reset the new field
        });
        setIsSubmitted(true);
      }, (err) => {
        console.error('Error sending email:', err);
        
        toast.error(translations.Checkout.emailFailureMessage || "Failed to send email.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };
  

  const handleOrderComplete = () => {
    clearCart();
    setOrderDetails(null);
    setIsSubmitted(false);

    toast.info(translations.Checkout.cartClearedMessage || "Cart has been cleared!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className="container py-3">
          <section className="py-3 py-md-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h2 className="mb-4">{translations.Checkout.title}</h2>
                  {isSubmitted ? (
                    <div className="alert alert-success text-center" role="alert">
                      <h4>{translations.Checkout.successMessage}</h4>
                      <h5>{translations.Checkout.yourOrder}</h5>
                      <ul className="list-unstyled">
                        {orderDetails.items.map((item) => (
                          <li key={item.id}>
                            {item.title} (Qty: {item.quantity || 1}, Price: {item.price})
                          </li>
                        ))}
                      </ul>
                      <h6>{translations.Checkout.totalPrice}: {orderDetails.totalPrice}€</h6>
                      <button onClick={handleOrderComplete} className="btn btn-primary">
                        {translations.Checkout.completeOrder}
                      </button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      {/* Form fields */}
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">{translations.Checkout.firstName}</label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="surname" className="form-label">{translations.Checkout.surname}</label>
                        <input
                          type="text"
                          id="surname"
                          name="surname"
                          className="form-control"
                          value={formData.surname}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">{translations.Checkout.city}</label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="form-control"
                          value={formData.city}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="postCode" className="form-label">{translations.Checkout.postCode}</label>
                        <input
                          type="text"
                          id="postCode"
                          name="postCode"
                          className="form-control"
                          value={formData.postCode}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="addressStreet" className="form-label">{translations.Checkout.addressStreet}</label>
                        <input
                          type="text"
                          id="addressStreet"
                          name="addressStreet"
                          className="form-control"
                          value={formData.addressStreet}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label">{translations.Checkout.phoneNumber}</label>
                        <input
                          type="tel"
                          id="phoneNumber"
                          name="phoneNumber"
                          className="form-control"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="floorApartment" className="form-label">{translations.Checkout.floorApartment}</label>
                        <input
                          type="text"
                          id="floorApartment"
                          name="floorApartment"
                          className="form-control"
                          value={formData.floorApartment}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="additionalDetails" className="form-label">{translations.additionalDetails}</label>
                        <textarea
                          id="additionalDetails"
                          name="additionalDetails"
                          className="form-control"
                          value={formData.additionalDetails}
                          onChange={handleInputChange}
                          maxLength={500} // Restrict to 500 characters
                          rows="4"
                          placeholder={translations.additionalDetailsPlaceholder}
                        />
                        <small className="form-text text-muted">{translations.additionalDetailsLimit}</small>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">{translations.Checkout.email}</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">{translations.Checkout.submitButton}</button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
