import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import openblue from './Fontana-logo-3.png'; // Logo path
import ReactCountryFlag from "react-country-flag"; // Country flag for language switch
import { useCart } from './CartContext'; // Cart context for managing shopping cart
import enTranslations from './locales/en.json'; // English translations
import esTranslations from './locales/es.json'; // Spanish translations

function Navbar() {
  const [query, setQuery] = useState(''); // Search query state
  const [suggestions, setSuggestions] = useState([]); // Search suggestions state
  const navigate = useNavigate(); // Navigation hook for routing
  const [selectedLanguage, setSelectedLanguage] = useState("ES"); // Default language
  const { cart } = useCart(); // Cart state from the context

  // Load the correct translations based on selected language
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es';  // Default to 'es'
    setSelectedLanguage(savedLanguage === 'en' ? 'GB' : 'ES');
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  // Searchable items for the suggestion list
  const items = [
    'home', 'contact', 'about', 'jobboard', 'suppliers', 'ourclients',
  ];

  // Handles input change in the search bar
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 1) {
      // Filter suggestions based on input value
      const filteredSuggestions = items.filter((item) =>
        item.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Handles click on a suggestion item
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    navigate('/' + suggestion); // Navigate to selected suggestion's route
  };

  // Handles form submission for search
  const handleSubmit = (e) => {
    e.preventDefault();
    if (items.includes(query.toLowerCase())) {
      navigate('/' + query.toLowerCase());
    }
  };

  // Handles language change and stores selection in localStorage
  const handleLanguageChange = (language) => {
    const langCode = language === "GB" ? 'en' : 'es';
    localStorage.setItem('language', langCode);
    setSelectedLanguage(language);
    window.location.reload(); // Reload the page to reflect language change
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom border-dark-subtle">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={openblue} alt="Logo" width="45" height="24" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* General dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {translations.general}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/contact">{translations.contact}</Link></li>
                <li><Link className="dropdown-item" to="/jobboard">{translations.jobBoard}</Link></li>
                <li><Link className="dropdown-item" to="/about">{translations.about}</Link></li>
                <li><Link className="dropdown-item" to="/ourclients">{translations.ourClients}</Link></li>
                <li><Link className="dropdown-item" to="/suppliers">{translations.suppliers}</Link></li>
              </ul>
            </li>

            {/* Laundry dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {translations.laundry}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/inquiryform">{translations.inquiryForm}</Link></li>
                <li><Link className="dropdown-item" to="/quoterequestform">{translations.quoteRequestForm}</Link></li>
                <li><Link className="dropdown-item" to="/informationonprovidedservices">{translations.infoOnProvidedServices}</Link></li>
              </ul>
            </li>

            {/* Products dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {translations.products}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/productspage">{translations.inquiryOfProducts}</Link></li>
                <li><Link className="dropdown-item" to="/products">{translations.products}</Link></li>

                <li><Link className="dropdown-item" to="/cart">{translations.cart}</Link></li>
              </ul>
            </li>

            {/* Co-Hosts Area dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {translations.coHostsArea}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/rentals">{translations.rentals}</Link></li>
                <li><Link className="dropdown-item" to="/reservationform">{translations.reservationForm}</Link></li>
              </ul>
            </li>
          </ul>

          {/* Cart button, only show if cart has items */}
          {cart.length > 0 && (
  <div className="cart-button-container me-3">
    <Link to="/cart" className={`btn btn-light cart-button ${cart.length > 0 ? 'hover-active' : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-cart-fill text-dark" viewBox="0 0 16 16">
        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
      </svg>
      <span className="cart-badge">{cart.length}</span>
    </Link>
  </div>
)}

          {/* Language selector */}
          <div className="dropdown me-3 language-selector">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <ReactCountryFlag countryCode={selectedLanguage} svg size={23} />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
              <li>
                <button className="dropdown-item" onClick={() => handleLanguageChange("GB")}>
                  <ReactCountryFlag countryCode="GB" svg style={{ marginRight: '8px' }} /> English
                </button>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => handleLanguageChange("ES")}>
                  <ReactCountryFlag countryCode="ES" svg style={{ marginRight: '8px' }} /> Español
                </button>
              </li>
            </ul>
          </div>

          {/* Search bar */}
          <form className="d-flex" onSubmit={handleSubmit}>
            <input className="form-control me-2" type="search" placeholder={translations.search} aria-label="Search" value={query} onChange={handleInputChange} />
            <button className="btn btn-outline-success" type="submit">{translations.searchButton}</button>
          </form>

          {/* Suggestions list */}
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li key={index} onClick={() => handleSuggestionClick(suggestion)} className="suggestion-item">
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
