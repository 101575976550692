// src/HomePage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function JobBoard() {
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  const openContactOptions = () => {
    setShowModal(true); // Show modal when contact is clicked
  };

  const closeModal = () => {
    setShowModal(false); // Close modal
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <section className=" py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center">{translations.welcomeMessage}</h2>
                <p className="text-secondary mb-5 text-center lead fs-4">{translations.subHeader}</p>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row gy-3 gy-md-4">
                    {/* Job 1: General Worker */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <h4 className="mb-4">{translations.generalWorkerTitle || "General Worker"}</h4>
                          <p className="mb-4 text-secondary">{translations.generalWorkerDescription || "Assists in various tasks across the site, ensuring smooth operations and productivity."}</p>
                          <button className="btn btn-outline-primary bsb-btn-circle" onClick={openContactOptions}>
                            {translations.contact}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Job 2: Manager */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <h4 className="mb-4">{translations.managerTitle || "Manager"}</h4>
                          <p className="mb-4 text-secondary">{translations.managerDescription || "Oversees operations, manages staff, and ensures that targets are met efficiently."}</p>
                          <button className="btn btn-outline-primary bsb-btn-circle" onClick={openContactOptions}>
                            {translations.contact}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Job 3: Delivery Driver */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <h4 className="mb-4">{translations.deliveryDriverTitle || "Delivery Driver"}</h4>
                          <p className="mb-4 text-secondary">{translations.deliveryDriverDescription || "Responsible for purchasing materials and delivering products to clients."}</p>
                          <button className="btn btn-outline-primary bsb-btn-circle" onClick={openContactOptions}>
                            {translations.contact}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>

      {/* Modal for Contact Options */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{translations.contactOptions}</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body text-center">
                <p>{translations.chooseContactMethod}</p>
                <a href="mailto:Nikolaigetov99@gmail.com" className="btn btn-primary mx-2">{translations.sendEmail || "Send Email"}</a>
                <a href="tel:+34672592101" className="btn btn-secondary mx-2">{translations.callUs || "Call Us"}</a>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>{translations.close || "Close"}</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default JobBoard;
