import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles and required modules
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

function OurClients() {
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="fs-6 text-secondary mb-2 text-uppercase text-center">{translations.clientsTitle}</h2>
                <h2 className="mb-4 display-5 text-center">{translations.welcomeMessage}</h2>
                <p className="fs-5 text-secondary mb-5 text-center">{translations.priorityMessage}</p>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
              </div>
            </div>
          </div>

          {/* Swiper Component */}
          <div className="container overflow-hidden">
          <Swiper
  modules={[Navigation, Pagination]}  // Include Navigation and Pagination modules
  spaceBetween={30}
  slidesPerView={1}
  pagination={{ clickable: true }}
  navigation
  loop={true}  // Enable continuous looping
  breakpoints={{
    640: { slidesPerView: 1, spaceBetween: 20 },
    768: { slidesPerView: 2, spaceBetween: 30 },
    1024: { slidesPerView: 3, spaceBetween: 40 },
  }}
  className="mySwiper"
>
  {[...Array(12)].map((_, index) => (
    <SwiperSlide key={index}>
      <div className="text-center card-slide">
        <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
          <h4>Client {index + 1}</h4>
          <p>Details about Client {index + 1}</p>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>

          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default OurClients;
