import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

// Map Configuration
const mapContainerStyle = {
  height: "400px",
  width: "100%"
};

const center = {
  lat: 40.3729831,
  lng: -3.6930684
};

const googleMapsApiKey = "AIzaSyALaMxBhdneMGRuJuyTxBJedPT0lAS8_K0"; // Replace with your API key

function About() {
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'en'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
<section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{translations.aboutUsTitle}</h2>
        <p class="text-secondary mb-5 text-center lead fs-4">                      {translations.aboutUsDescription}
        </p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-4 gy-lg-0 align-items-lg-center">
    <div className="col-12 col-lg-6">
  <LoadScript googleMapsApiKey={googleMapsApiKey}>
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={15}
    >
      <Marker position={center} />
    </GoogleMap>
  </LoadScript>
</div>

      <div class="col-12 col-lg-6 col-xxl-6">
        <div class="row justify-content-lg-end">
          <div class="col-12 col-lg-11">
            <div class="about-wrapper">
              <p class="lead mb-4 mb-md-5"> {translations.aboutUsDescription2}</p>
              <div class="row gy-4 mb-4 mb-md-5">
                <div class="col-12 col-md-6">
                  <div class="card border border-dark">
                    <div class="card-body p-4">
                      <h3 class="display-5 fw-bold text-primary text-center mb-2">{translations.hours}</h3>
                      <p class="fw-bold text-center m-0">8:00 - 23:00</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="card border border-dark">
                    <div class="card-body p-4">
                      <h3 class="display-5 fw-bold text-primary text-center mb-2">100+</h3>
                      <p class="fw-bold text-center m-0">{translations.satisfiedcustomers}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <a 
  href="https://www.google.com/maps/place/Open+Blue+Usera/@40.3729831,-3.6930684,17z/data=!3m1!4b1!4m6!3m5!1s0xd42271540a244b3:0xc5ef6d6e78981bb8!8m2!3d40.372979!4d-3.6904935!16s%2Fg%2F11l6bkdrm3?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" 
  class="btn btn-primary bsb-btn-2xl" 
  target="_blank" 
  rel="noopener noreferrer"
>
{translations.explore}
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
  </svg>
</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      </div>
      <Footer />
    </div>
  );
}

export default About;
