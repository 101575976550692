// src/InformationOnProvidedServicesPage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function InformationOnProvidedServices() {
  const [language, setLanguage] = useState('es');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'es'; // default to 'es'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="App-Header">
        <section className="bsb-service-7 py-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                <h3 className="fs-5 mb-2 text-secondary text-center text-uppercase">
                  {translations.servicesTitle}
                </h3>
                <h2 className="display-5 mb-5 mb-xl-9 text-center">
                  {translations.servicesSubtitle}
                </h2>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="container-fluid bg-light border shadow">
                  <div className="row">
                    <div className="col-12 col-md-4 p-0">
                      <div className="card border-0 bg-transparent">
                        <div className="card-body text-center p-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-1-circle text-primary mb-4" viewBox="0 0 16 16">
                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z"/>
                        </svg>                      <h4 className="fw-bold text-uppercase mb-4">{translations.washingTitle}</h4>
                          <p className="mb-4 text-secondary">{translations.washingDescription}</p>
                          <a href="#!" className="fw-bold text-decoration-none link-primary">
                            {translations.learnMore}
                            {/* SVG Arrow Icon here */}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 p-0 border-top border-bottom border-start border-end">
                      <div className="card border-0 bg-transparent">
                        <div className="card-body text-center p-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-2-circle text-primary mb-4" viewBox="0 0 16 16">
                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306"/>
                        </svg>                            <h4 className="fw-bold text-uppercase mb-4">{translations.dryingTitle}</h4>
                          <p className="mb-4 text-secondary">{translations.dryingDescription}</p>
                          <a href="#!" className="fw-bold text-decoration-none link-primary">
                            {translations.learnMore}
                            {/* SVG Arrow Icon here */}
                          </a>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-4 p-0">
                      <div className="card border-0 bg-transparent">
                        <div className="card-body text-center p-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-3-circle text-primary mb-4" viewBox="0 0 16 16">
                        <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318"/>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8"/>                        </svg>                            <h4 className="fw-bold text-uppercase mb-4">{translations.ironingTitle}</h4>
                          <p className="mb-4 text-secondary">{translations.ironingDescription}</p>
                          <a href="#!" className="fw-bold text-decoration-none link-primary">
                            {translations.learnMore}
                            {/* SVG Arrow Icon here */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
 
export default InformationOnProvidedServices;
